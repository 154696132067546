<template>
  <div class="container">
    <div class="header">
    </div>
    <div class="content card-box" v-loading="loading" style="overflow-y:scroll">
      <el-card shadow="always" :body-style="{ padding: '20px' }" v-for="(item,i) in linkList" :key="i">
        <div slot="header" style="text-align:left">
          {{item.relateUnitName}}
          <div style="float: right;">
            <el-tooltip content="关联单位信息" placement="top" effect="dark">
              <el-button style="padding: 3px 0;color:#409EFF" type="text" @click="open('unit',item.relateUnitId)" icon="el-icon-search"></el-button>
            </el-tooltip>
            <el-tooltip content="单位设备" placement="top" effect="dark">
              <el-button style="padding: 3px 0;color:#E6A23C" type="text" @click="open('device',item.relateUnitId)" icon="el-icon-setting"></el-button>
            </el-tooltip>
            <el-tooltip content="删除关联" placement="top" effect="dark">
              <el-button style=" padding: 3px 0;color:#F56C6C" type="text" @click="delLink(item.id)" icon="el-icon-delete"></el-button>
            </el-tooltip>
          </div>
        </div>
        <div>
          <el-tag type="primary" size="normal">设备数量:{{item.equipmentCount}}</el-tag>
          <el-tag type="primary" size="normal">NFC巡检:{{item.nfcCount}}</el-tag>
          <el-tag type="primary" size="normal">设备维护保养报告:{{item.deviceProtectReportCount}}</el-tag>
          <el-tag type="primary" size="normal">消防控制室值班记录:{{item.fireControlDutyRecordCount}}</el-tag>
          <el-tag type="primary" size="normal">责令整改:{{item.rectifyNoticeCount}}</el-tag>
          <el-tag type="primary" size="normal">防火巡检:{{item.firePatrolCount}}</el-tag>
        </div>
      </el-card>
      <p v-show="linkList.length===0">暂无数据</p>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="20" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 单位详情窗口 -->
    <el-dialog title="单位详情" :visible.sync="infoShow" fullscreen class="unit-info" @closed="close">
      <el-header class="unit-header">
        <div class="header-menu">
          <el-menu class="el-menu-demo" mode="horizontal" :router="true">
            <template v-for="(item,i) in menu">
              <el-submenu :key="i" v-if="item.children" :index="item.id">
                <template slot="title">
                  <i :class="item.icon"></i>
                  <span slot="title">{{item.text}}</span>
                </template>
                <template v-for="(subitem,j) in item.children">
                  <el-menu-item :key="j" :index="subitem.path">
                    <i :class="subitem.icon"></i>
                    <span slot="title">{{subitem.text}}</span>
                  </el-menu-item>
                </template>
              </el-submenu>
              <el-menu-item :key="i" :index="item.path" v-else>
                <i :class="item.icon"></i>
                <span slot="title">{{item.text}}</span>
              </el-menu-item>
            </template>
          </el-menu>
        </div>
      </el-header>
      <main class="unit-main">
        <transition name="el-fade-in-linear">
          <router-view v-show="true" :companyId="companyId" :isAdmin="false" :isUnit="true"></router-view>
        </transition>
      </main>
    </el-dialog>
    <!-- 设备列表 -->
    <el-dialog title="安装设备列表" :visible.sync="deviceShow" width="80%" top="2%" v-dialogDrag :close-on-click-modal="false">
      <div class="container noangle" v-loading="deviceLoading">
        <div class="header">
          <el-form :inline="true" :model="searchDeviceForm" class="demo-form-inline">
            <el-form-item label="设备特征码">
              <el-input v-model="searchDeviceForm.equipmentCode" autocomplete="off" size="small" placeholder="请输入单位名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="设备类型">
              <el-select v-model="searchDeviceForm.typeId" placeholder="请选择设备类型" size="small" clearable>
                <el-option v-for="(item,i) in deviceTypeList" :key="i" :label="item.typeName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="initDevice">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="content">
          <el-table :data="deviceList" stripe style="width: 100%" height="500px">
            <el-table-column prop="typeName" label="设备类型" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="unitName" label="所在单位" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="installName" label="安装建筑物" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="equipmentCode" label="设备特征码" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="stationCode" label="基站特征码" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="value" label="当前数值" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-show="scope.row.typeId===3||scope.row.typeId===4">{{scope.row.value}}mpa</span>
                <span v-show="scope.row.typeId===5||scope.row.typeId===6">{{scope.row.value}}M</span>
              </template>
            </el-table-column>
            <el-table-column prop="contactPhone" label="联系电话" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="installTime" label="安装时间" show-overflow-tooltip>
              <template slot-scope="scope">
                {{scope.row.installTime|timeFormat}}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="footer">
          <el-pagination @current-change="handleDeviceChange" :current-page.sync="devicePage" :page-size="10" layout="total, prev, pager, next, jumper" :total="deviceTotal">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: 1,
      total: 0,
      devicePage: 1,
      deviceTotal: 0,
      infoShow: false,
      deviceShow: false,
      loading: false,
      deviceLoading: false,
      linkList: [],
      deviceList: [],
      deviceTypeList: [],
      searchDeviceForm: {},
      companyId: '',
      menu: [{
        id: '9012',
        text: 'NFC巡检',
        path: '/home/link/NFC',
        icon: 'el-icon-mobile-phone'
      }, {
        id: '9013',
        text: '防火巡检',
        path: '/home/link/inspect',
        icon: 'el-icon-map-location'
      }, {
        id: '9014',
        text: '消防控制室值班记录',
        path: '/home/link/dutyrecord',
        icon: 'el-icon-s-order'
      }, {
        id: '9015',
        text: '设施维护保养报告',
        path: '/home/link/devicereport',
        icon: 'el-icon-document'
      }, {
        id: '9016',
        text: '责令整改',
        path: '/home/link/correct',
        icon: 'el-icon-time'
      }]
    }
  },
  created () {
    this.initTable()
    this.getDeviceTypeList()
  },
  methods: {
    handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    handleDeviceChange (val) {
      this.devicePage = val
      this.initDevice()
    },
    // 初始化表格
    async initTable () {
      const data = {
        pageNo: this.currentPage,
        pageSize: 20
      }
      this.loading = true
      const { data: result } = await this.$axios.get('/unitRelate/list', { params: data })
      if (result.code === 200) {
        this.linkList = result.data.result
        this.total = result.data.total
        this.loading = false
      } else {
        this.$message.error(result.msg)
      }
    },
    // 初始化设备列表
    async initDevice () {
      const data = {}
      if (this.searchDeviceForm.typeId) {
        data.typeId = this.searchDeviceForm.typeId
      }
      if (this.searchDeviceForm.equipmentCode) {
        data.equipmentCode = this.searchDeviceForm.equipmentCode
      }
      data.pageNo = this.devicePage
      data.pageSize = 10
      data.unitId = this.companyId
      this.deviceLoading = true
      const { data: result } = await this.$axios.get('/equipment/list', { params: data })
      if (result.code === 200) {
        this.deviceList = result.data.result
        this.deviceTotal = result.data.total
        this.deviceLoading = false
      }
    },
    async delLink (id) {
      const confirm = await this.$confirm('此操作将永久删除该联动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const data = {
          unitId: this.unitId,
          id: id
        }
        const { data: result } = await this.$axios.delete('/unitRelate/remove', data)
        if (result.code === 200) {
          this.$message.success('删除成功')
          this.initLink()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 查找设备类型
    async getDeviceTypeList () {
      const { data: result } = await this.$axios.get('/equipment-type/list')
      if (result.code === 200) {
        this.deviceTypeList = result.data
      }
    },
    open (flag, row) {
      switch (flag) {
        case 'unit':
          this.companyId = row
          this.infoShow = true
          this.$router.push('/home/link/NFC')
          break
        case 'device':
          this.companyId = row
          this.initDevice()
          this.deviceShow = true
          break
      }
    },
    close () {
      console.log(this.path)
      this.$router.push('/home/unit/link')
    }
  }
}
</script>

<style lang="scss" scoped>
.card-box {
  display: flex;
  flex-wrap: wrap;
  .el-card {
    width: 24%;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 20px;
    ::v-deep .el-card__header {
      padding: 10px;
    }
    ::v-deep .el-card__body {
      padding: 15px !important;
      div {
        display: flex;
        flex-direction: column;
        .el-tag {
          margin-bottom: 5px;
        }
      }
    }
  }
  .el-card:nth-child(4n) {
    margin-right: 0;
  }
}
.unit-info {
  ::v-deep .el-dialog__header {
    height: 24px;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
    height: calc(100vh - 54px);
    .unit-header {
      background-color: rgba(67, 77, 120, 0.15);
      color: #39b8e6;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      .header-menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 60px;
        width: 100%;
        .el-menu {
          height: 100%;
          display: flex;
          justify-content: center;
          background-color: transparent !important;
          border-bottom: none !important;
          .el-menu-item,
          .el-submenu__title {
            height: 100%;
            color: #39b8e6 !important;
            i {
              color: #39b8e6 !important;
            }
          }
          //设置选中el-menu-item时的ff2556样式
          .el-menu-item .is-active,
          .el-submenu__title .is-active {
            background: linear-gradient(
              180deg,
              rgba(60, 192, 241, 0) 0%,
              rgba(60, 192, 241, 0.24) 100%
            ) !important;
            color: #39b8e6 !important;
            i {
              color: #39b8e6 !important;
            }
          }
          //设置鼠标悬停时el-menu-item的样式
          .el-menu-item:hover,
          .el-submenu__title:hover {
            background: linear-gradient(
              180deg,
              rgba(60, 192, 241, 0) 0%,
              rgba(60, 192, 241, 0.24) 100%
            ) !important;
            color: #39b8e6 !important;
            i {
              color: #39b8e6 !important;
            }
          }
          .el-menu-item:focus,
          .el-submenu__title:focus {
            background: linear-gradient(
              180deg,
              rgba(60, 192, 241, 0) 0%,
              rgba(60, 192, 241, 0.24) 100%
            ) !important;
            color: #39b8e6 !important;
            i {
              color: #39b8e6 !important;
            }
          }
        }
      }
    }
    .unit-main {
      height: calc(100% - 76px);
      padding: 15px;
      box-sizing: border-box;
      .container {
        background-image: none;
      }
    }
  }
}
</style>
